import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { auth } from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { environment, httpBasicOptions, httpAdminBasicOptions } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class StoreService {

  user$: any = [];
  constructor(
    private http: HttpClient,
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService
  ) {

  }


  createWhitelabelRequest(data: any): Observable<any> {
    let requestData = JSON.stringify({
      "email": data.email,
      "store_name": data.store_name
    });
    const url = environment.rootCloudUrl + 'createWhitelabelRequest';
    return this.http.post(url, requestData, httpBasicOptions)
      .pipe(
        map((response: Response) => response)
      );
  }


  getStoreDetailsByEmailID(data: any): Observable<any> {
    let requestData = JSON.stringify({
      "email": data.email
    });
    const url = environment.rootCloudUrl + 'getStoreDetailsByEmailID';
    return this.http.post(url, requestData, httpBasicOptions)
      .pipe(
        map((response: Response) => response)
      );
  }

  saveCategoriesDisplayOrder(data: any): Observable<any> {

    const url = environment.rootCloudUrl + 'saveCategoriesDisplayOrder';
    return this.http.post(url, data, httpBasicOptions)
      .pipe(
        map((response: Response) => response)
      );
  }

  getInsights(data: any): Observable<any> {
    let requestData = JSON.stringify({
      "store_id": data.store_id
    });
    const url = environment.rootCloudUrl + 'getInsights';
    return this.http.post(url, requestData, httpAdminBasicOptions)
      .pipe(
        map((response: Response) => response)
      )
  }
  getViewFromBigQuery(data: any): Observable<any> {
    let requestData = JSON.stringify({
      "store_name": data.store_id
    });
    const url = environment.rootCloudUrl + 'setupInsightsDataSet';
    return this.http.post(url, requestData, httpAdminBasicOptions)
      .pipe(
        map((response: Response) => response)
      )
  }

  getStoreLagalPagesByEmailID(data: any): Observable<any> {

    const url = environment.rootCloudUrl + 'getStoreLagalPagesByEmailID';
    return this.http.post(url, data, httpBasicOptions)
      .pipe(
        map((response: Response) => response)
      );
  }

  submitTermsAndConditions(data: any): Observable<any> {

    const url = environment.rootCloudUrl + 'submitTermsAndConditions';
    return this.http.post(url, data, httpBasicOptions)
      .pipe(
        map((response: Response) => response)
      )
  }




  submitDisclaimer(data: any): Observable<any> {

    const url = environment.rootCloudUrl + 'submitDisclaimer';
    return this.http.post(url, data, httpBasicOptions)
      .pipe(
        map((response: Response) => response)
      )
  }

  submitPrivacyPolicy(data: any): Observable<any> {

    const url = environment.rootCloudUrl + 'submitPrivacyPolicy';
    return this.http.post(url, data, httpBasicOptions)
      .pipe(
        map((response: Response) => response)
      )
  }


  submitShippingAndPaymentInfo(data: any): Observable<any> {

    const url = environment.rootCloudUrl + 'submitShippingAndPaymentInfo';
    return this.http.post(url, data, httpBasicOptions)
      .pipe(
        map((response: Response) => response)
      )
  }





  submitReturnPolicy(data: any): Observable<any> {

    const url = environment.rootCloudUrl + 'submitReturnPolicy';
    return this.http.post(url, data, httpBasicOptions)
      .pipe(
        map((response: Response) => response)
      )
  }

  submitAboutUs(data: any): Observable<any> {

    const url = environment.rootCloudUrl + 'submitAboutUs';
    return this.http.post(url, data, httpBasicOptions)
      .pipe(
        map((response: Response) => response)
      )
  }

  getStoreReviewsByEmailID(data: any): Observable<any> {
    let requestData = JSON.stringify({
      "email": data.email
    });
    const url = environment.rootCloudUrl + 'getStoreReviewsByEmailID';
    return this.http.post(url, requestData, httpBasicOptions)
      .pipe(
        map((response: Response) => response)
      );
  }



  getProductDetailsById(data: any): Observable<any> {
    let requestData = JSON.stringify({
      "id": data.id,
      "store_id": data.store_name
    });
    const url = environment.rootCloudUrl + 'getProductDetailsById';
    return this.http.post(url, requestData, httpBasicOptions)
      .pipe(
        map((response: Response) => response)
      );
  }


  getDeliveryDetailsById(data: any): Observable<any> {
    let requestData = JSON.stringify({
      "id": data.id,
      "store_id": data.store_name
    });
    const url = environment.rootCloudUrl + 'getDeliveryDetailsById';
    return this.http.post(url, requestData, httpBasicOptions)
      .pipe(
        map((response: Response) => response)
      );
  }
  getBlogDetailsByID(data: any): Observable<any> {
    let requestData = JSON.stringify({
      "id": data.id,
      "store_id": data.store_name
    });
    const url = environment.rootCloudUrl + 'getBlogDetailsByID';
    return this.http.post(url, requestData, httpBasicOptions)
      .pipe(
        map((response: Response) => response)
      );
  }


  
  getBankDetailsById(data: any): Observable<any> {
    let requestData = JSON.stringify({
      "id": data.id,
      "store_id": data.store_name
    });
    const url = environment.rootCloudUrl + 'getBankDetailsById';
    return this.http.post(url, requestData, httpBasicOptions)
      .pipe(
        map((response: Response) => response)
      );
  }


  getAppointmentOptionDetailsById(data: any): Observable<any> {
    let requestData = JSON.stringify({
      "id": data.id,
      "store_id": data.store_name
    });
    const url = environment.rootCloudUrl + 'getAppointmentOptionDetailsById';
    return this.http.post(url, requestData, httpBasicOptions)
      .pipe(
        map((response: Response) => response)
      );
  }


  getCategoryDetailsById(data: any): Observable<any> {
    let requestData = JSON.stringify({
      "id": data.id,
      "store_id": data.store_name
    });
    const url = environment.rootCloudUrl + 'getCategoryDetailsById';
    return this.http.post(url, requestData, httpBasicOptions)
      .pipe(
        map((response: Response) => response)
      );
  }



  getProductsByCategoryId(data: any): Observable<any> {
    let requestData = JSON.stringify({
      "id": data.id,
      "store_id": data.store_name
    });
    const url = environment.rootCloudUrl + 'getProductsByCategoryId';
    return this.http.post(url, requestData, httpBasicOptions)
      .pipe(
        map((response: Response) => response)
      );
  }

  checkStoreAccountExists(data: any): Observable<any> {
    let requestData = JSON.stringify({
      "store_id": data.store_name
    });
    const url = environment.rootCloudUrl + 'checkStoreAccountExists';
    return this.http.post(url, requestData, httpBasicOptions)
      .pipe(
        map((response: Response) => response)
      )
  }

  getStoreInventory(data: any): Observable<any> {
    let requestData = JSON.stringify({
      "store_id": data.store_name
    });
    const url = environment.rootCloudUrl + 'getStoreInventory';
    return this.http.post(url, requestData, httpBasicOptions)
      .pipe(
        map((response: Response) => response)
      )
  }


  getStoreOrderDetailsByEmailID(data: any): Observable<any> {
    let requestData = JSON.stringify({
      "email": data.email
    });
    const url = environment.rootCloudUrl + 'getStoreOrderDetailsByEmailID';
    return this.http.post(url, requestData, httpBasicOptions)
      .pipe(
        map((response: Response) => response)
      )
  }


  getStoreOrderDetailsByEmailIDPaginated(data: any): Observable<any> {
    const url = environment.rootCloudUrl + 'getStoreOrderDetailsByEmailIDPaginated';
    return this.http.post(url, data, httpBasicOptions)
      .pipe(
        map((response: Response) => response)
      )
  }


  getStoreAppointmentDetailsByEmailID(data: any): Observable<any> {
    let requestData = JSON.stringify({
      "email": data.email
    });
    const url = environment.rootCloudUrl + 'getStoreAppointmentDetailsByEmailID';
    return this.http.post(url, requestData, httpBasicOptions)
      .pipe(
        map((response: Response) => response)
      )
  }

  viewStoreOrderDetailsByOrderID(data: any): Observable<any> {
    let requestData = JSON.stringify({
      "email": data.email,
      "order_id": data.order_id
    });
    const url = environment.rootCloudUrl + 'viewStoreOrderDetailsByOrderID';
    return this.http.post(url, requestData, httpBasicOptions)
      .pipe(
        map((response: Response) => response)
      )
  }

  getStoreCouponDetailsByEmailID(data: any): Observable<any> {
    let requestData = JSON.stringify({
      "email": data.email
    });
    const url = environment.rootCloudUrl + 'getStoreCouponDetailsByEmailID';
    return this.http.post(url, requestData, httpBasicOptions)
      .pipe(
        map((response: Response) => response)
      )
  }

  getCouponsAvailedByID(data: any): Observable<any> {
    const url = environment.rootCloudUrl + 'getCouponsAvailedByID';
    return this.http.post(url, data, httpBasicOptions)
      .pipe(
        map((response: Response) => response)
      )
  }

  getStoreDeliveryOptionDetailsByEmailID(data: any): Observable<any> {
    let requestData = JSON.stringify({
      "email": data.email
    });
    const url = environment.rootCloudUrl + 'getStoreDeliveryOptionDetailsByEmailID';
    return this.http.post(url, requestData, httpBasicOptions)
      .pipe(
        map((response: Response) => response)
      )
  }

  getStoreBlogDetailsByEmailID(data: any): Observable<any> {
    let requestData = JSON.stringify({
      "email": data.email
    });
    const url = environment.rootCloudUrl + 'getStoreBlogDetailsByEmailID';
    return this.http.post(url, requestData, httpBasicOptions)
      .pipe(
        map((response: Response) => response)
      )
  }

  getStoreBankOptionDetailsByEmailID(data: any): Observable<any> {
    let requestData = JSON.stringify({
      "email": data.email
    });
    const url = environment.rootCloudUrl + 'getStoreBankOptionDetailsByEmailID';
    return this.http.post(url, requestData, httpBasicOptions)
      .pipe(
        map((response: Response) => response)
      )
  }


  getStoreAppointmentOptionDetailsByEmailID(data: any): Observable<any> {
    let requestData = JSON.stringify({
      "email": data.email
    });
    const url = environment.rootCloudUrl + 'getStoreAppointmentOptionDetailsByEmailID';
    return this.http.post(url, requestData, httpBasicOptions)
      .pipe(
        map((response: Response) => response)
      )
  }

  getStorePickupDaysDetailsByEmailID(data: any): Observable<any> {
    let requestData = JSON.stringify({
      "email": data.email
    });
    const url = environment.rootCloudUrl + 'getStorePickupDaysDetailsByEmailID';
    return this.http.post(url, requestData, httpBasicOptions)
      .pipe(
        map((response: Response) => response)
      )
  }


  getStoreCustomerDetailsByEmailID(data: any): Observable<any> {
    let requestData = JSON.stringify({
      "email": data.email
    });
    const url = environment.rootCloudUrl + 'getStoreCustomerDetailsByEmailID';
    return this.http.post(url, requestData, httpBasicOptions)
      .pipe(
        map((response: Response) => response)
      )
  }

  getStoreCustomerDetailsByEmailIDPaginated(data: any): Observable<any> {
    const url = environment.rootCloudUrl + 'getStoreCustomerDetailsByEmailIDPaginated';
    return this.http.post(url, data, httpBasicOptions)
      .pipe(
        map((response: Response) => response)
      )
  }




  getAllAccounts(data: any): Observable<any> {
    let requestData = JSON.stringify({
      "email": data.email,
      "secret": data.secret
    });
    const url = environment.rootCloudUrl + 'getAllStoreDetailsList';
    return this.http.post(url, requestData, httpAdminBasicOptions)
      .pipe(
        map((response: Response) => response)
      )
  }

  getAllReports(data: any): Observable<any> {
    let requestData = JSON.stringify({
      "email": data.email,
      "secret": data.secret
    });
    const url = environment.rootCloudUrl + 'getAllReportList';
    return this.http.post(url, requestData, httpAdminBasicOptions)
      .pipe(
        map((response: Response) => response)
      )
  }

  getStoreDashboardDetailsByEmailID(data: any): Observable<any> {
    let requestData = JSON.stringify({
      "email": data.email
    });
    const url = environment.rootCloudUrl + 'getStoreDashboardDetailsByEmailID';
    return this.http.post(url, requestData, httpBasicOptions)
      .pipe(
        map((response: Response) => response)
      )
  }


  uploadStoreInventory(event: any): Observable<any> {
    const file = event.target.files[0];
    let formData: FormData = new FormData();
    formData.append('id', JSON.parse(localStorage.getItem('currentUserProfile')).email);
    formData.append('store_name', JSON.parse(localStorage.getItem('currentUserProfile')).store_name);
    formData.append('sheet', file);
    const url = environment.rootCloudUrl + 'uploadStoreInventorySets?store_id=' + JSON.parse(localStorage.getItem('currentUserProfile')).store_name;

    return this.http.post(url, formData)
      .pipe(
        map((response: Response) => response)
      )
  }



  uploadProductImage(event: any): Observable<any> {
    const file = event.target.files[0];
    let formData: FormData = new FormData();
    formData.append('id', JSON.parse(localStorage.getItem('currentUserProfile')).email);
    formData.append('store_name', JSON.parse(localStorage.getItem('currentUserProfile')).store_name);
    formData.append('photo', file);
    const url = environment.rootCloudUrl + 'uploadProductImage?store_name=' + JSON.parse(localStorage.getItem('currentUserProfile')).store_name;
    return this.http.post(url, formData)
      .pipe(
        map((response: Response) => response)
      )
  }


  uploadAdditionalProductImageAndSave(event: any, id: any): Observable<any> {
    const file = event.target.files[0];
    let formData: FormData = new FormData();
    formData.append('id', JSON.parse(localStorage.getItem('currentUserProfile')).email);
    formData.append('store_name', JSON.parse(localStorage.getItem('currentUserProfile')).store_name);
    formData.append('photo', file);
    formData.append('product_id', id);
    const url = environment.rootCloudUrl + 'uploadAdditionalProductImageAndSave?store_name=' + JSON.parse(localStorage.getItem('currentUserProfile')).store_name + '&product_id=' + id;
    return this.http.post(url, formData)
      .pipe(
        map((response: Response) => response)
      )
  }


  uploadReviewImage(event: any): Observable<any> {
    const file = event.target.files[0];
    let formData: FormData = new FormData();
    formData.append('id', JSON.parse(localStorage.getItem('currentUserProfile')).email);
    formData.append('store_name', JSON.parse(localStorage.getItem('currentUserProfile')).store_name);
    formData.append('photo', file);
    const url = environment.rootCloudUrl + 'uploadReviewImage?store_name=' + JSON.parse(localStorage.getItem('currentUserProfile')).store_name;
    return this.http.post(url, formData)
      .pipe(
        map((response: Response) => response)
      )
  }


  uploadSlideImage(event: any): Observable<any> {
    const file = event.target.files[0];
    let formData: FormData = new FormData();
    formData.append('id', JSON.parse(localStorage.getItem('currentUserProfile')).email);
    formData.append('store_name', JSON.parse(localStorage.getItem('currentUserProfile')).store_name);
    formData.append('photo', file);
    const url = environment.rootCloudUrl + 'uploadSlideImage?store_name=' + JSON.parse(localStorage.getItem('currentUserProfile')).store_name;
    return this.http.post(url, formData)
      .pipe(
        map((response: Response) => response)
      )
  }

  uploadSlideImageAndSave(event: any): Observable<any> {
    const file = event.target.files[0];
    let formData: FormData = new FormData();
    formData.append('id', JSON.parse(localStorage.getItem('currentUserProfile')).email);
    formData.append('store_name', JSON.parse(localStorage.getItem('currentUserProfile')).store_name);
    formData.append('photo', file);
    const url = environment.rootCloudUrl + 'uploadSlideImageAndSave?store_name=' + JSON.parse(localStorage.getItem('currentUserProfile')).store_name;
    return this.http.post(url, formData)
      .pipe(
        map((response: Response) => response)
      )
  }


  uploadCategoryImage(event: any): Observable<any> {
    const file = event.target.files[0];
    let formData: FormData = new FormData();
    formData.append('id', JSON.parse(localStorage.getItem('currentUserProfile')).email);
    formData.append('store_name', JSON.parse(localStorage.getItem('currentUserProfile')).store_name);
    formData.append('photo', file);
    const url = environment.rootCloudUrl + 'uploadCategoryImage?store_name=' + JSON.parse(localStorage.getItem('currentUserProfile')).store_name;
    return this.http.post(url, formData)
      .pipe(
        map((response: Response) => response)
      )
  }
  uploadBlogImage(event: any): Observable<any> {
    const file = event.target.files[0];
    let formData: FormData = new FormData();
    formData.append('id', JSON.parse(localStorage.getItem('currentUserProfile')).email);
    formData.append('store_name', JSON.parse(localStorage.getItem('currentUserProfile')).store_name);
    formData.append('photo', file);
    const url = environment.rootCloudUrl + 'uploadBlogImage?store_name=' + JSON.parse(localStorage.getItem('currentUserProfile')).store_name;
    return this.http.post(url, formData)
      .pipe(
        map((response: Response) => response)
      )
  }



  replaceSlideImage(event: any, id: any): Observable<any> {
    const file = event.target.files[0];
    let formData: FormData = new FormData();
    formData.append('id', JSON.parse(localStorage.getItem('currentUserProfile')).email);
    formData.append('store_name', JSON.parse(localStorage.getItem('currentUserProfile')).store_name);
    formData.append('slide_id', id);
    formData.append('photo', file);
    const url = environment.rootCloudUrl + 'replaceSlideImage?store_name=' + JSON.parse(localStorage.getItem('currentUserProfile')).store_name + '&slide_id=' + id;
    return this.http.post(url, formData)
      .pipe(
        map((response: Response) => response)
      )
  }


  replaceCategoryImage(event: any, id: any): Observable<any> {
    const file = event.target.files[0];
    let formData: FormData = new FormData();
    formData.append('id', JSON.parse(localStorage.getItem('currentUserProfile')).email);
    formData.append('store_name', JSON.parse(localStorage.getItem('currentUserProfile')).store_name);
    formData.append('sub_category_id', id);
    formData.append('photo', file);
    const url = environment.rootCloudUrl + 'replaceCategoryImage?store_name=' + JSON.parse(localStorage.getItem('currentUserProfile')).store_name + '&sub_category_id=' + id;
    return this.http.post(url, formData)
      .pipe(
        map((response: Response) => response)
      )
  }

  replaceProductImage(event: any, id: any): Observable<any> {
    const file = event.target.files[0];
    let formData: FormData = new FormData();
    formData.append('id', JSON.parse(localStorage.getItem('currentUserProfile')).email);
    formData.append('store_name', JSON.parse(localStorage.getItem('currentUserProfile')).store_name);
    formData.append('product_id', id);
    formData.append('photo', file);
    const url = environment.rootCloudUrl + 'replaceProductImage?store_name=' + JSON.parse(localStorage.getItem('currentUserProfile')).store_name + '&product_id=' + id;
    return this.http.post(url, formData)
      .pipe(
        map((response: Response) => response)
      )
  }




  deleteBGImage(id: any): Observable<any> {
    const url = environment.rootCloudUrl + 'deleteBGImage?store_id=' + JSON.parse(localStorage.getItem('currentUserProfile')).store_name;
    return this.http.delete(url, httpBasicOptions)
      .pipe(
        map((response: Response) => response)
      )
  }

  deleteSlide(id: any): Observable<any> {
    const url = environment.rootCloudUrl + 'deleteSlide?store_id=' + JSON.parse(localStorage.getItem('currentUserProfile')).store_name + '&slide_id=' + id;
    return this.http.delete(url, httpBasicOptions)
      .pipe(
        map((response: Response) => response)
      )
  }

  deleteCategory(id: any): Observable<any> {
    const url = environment.rootCloudUrl + 'deleteCategory?store_id=' + JSON.parse(localStorage.getItem('currentUserProfile')).store_name + '&category_id=' + id;
    return this.http.delete(url, httpBasicOptions)
      .pipe(
        map((response: Response) => response)
      )
  }

  deleteCoupon(id: any): Observable<any> {
    console.log(id);
    const url = environment.rootCloudUrl + 'deleteCoupon?store_id=' + JSON.parse(localStorage.getItem('currentUserProfile')).store_name + '&coupon_id=' + id;
    return this.http.delete(url, httpBasicOptions)
      .pipe(
        map((response: Response) => response)
      )
  }
  deleteDeliveryOption(id: any): Observable<any> {
    console.log(id);
    const url = environment.rootCloudUrl + 'deleteDeliveryOption?store_id=' + JSON.parse(localStorage.getItem('currentUserProfile')).store_name + '&delivery_option_id=' + id;
    return this.http.delete(url, httpBasicOptions)
      .pipe(
        map((response: Response) => response)
      )
  }
  deleteBlog(id: any): Observable<any> {
    console.log(id);
    const url = environment.rootCloudUrl + 'deleteBlog?store_id=' + JSON.parse(localStorage.getItem('currentUserProfile')).store_name + '&blog_id=' + id;
    return this.http.delete(url, httpBasicOptions)
      .pipe(
        map((response: Response) => response)
      )
  }
  deleteBankOption(id: any): Observable<any> {
    console.log(id);
    const url = environment.rootCloudUrl + 'deleteBankOption?store_id=' + JSON.parse(localStorage.getItem('currentUserProfile')).store_name + '&delivery_option_id=' + id;
    return this.http.delete(url, httpBasicOptions)
      .pipe(
        map((response: Response) => response)
      )
  }
  deleteAppointmentOption(id: any): Observable<any> {
    console.log(id);
    const url = environment.rootCloudUrl + 'deleteAppointmentOption?store_id=' + JSON.parse(localStorage.getItem('currentUserProfile')).store_name + '&appointment_option_id=' + id;
    return this.http.delete(url, httpBasicOptions)
      .pipe(
        map((response: Response) => response)
      )
  }

  deleteOptions(id: any, productId: any): Observable<any> {
    console.log(id);
    const url = environment.rootCloudUrl + 'deleteOptions?store_id=' + JSON.parse(localStorage.getItem('currentUserProfile')).store_name + '&option_id=' + id + '&product_id=' + productId;
    return this.http.delete(url, httpBasicOptions)
      .pipe(
        map((response: Response) => response)
      )
  }


  deleteExtras(id: any, productId: any): Observable<any> {
    console.log(id);
    const url = environment.rootCloudUrl + 'deleteExtras?store_id=' + JSON.parse(localStorage.getItem('currentUserProfile')).store_name + '&extra_id=' + id + '&product_id=' + productId;
    return this.http.delete(url, httpBasicOptions)
      .pipe(
        map((response: Response) => response)
      )
  }


  deleteAppointment(id: any): Observable<any> {
    console.log(id);
    const url = environment.rootCloudUrl + 'deleteAppointment?store_id=' + JSON.parse(localStorage.getItem('currentUserProfile')).store_name + '&appointment_id=' + id;
    return this.http.delete(url, httpBasicOptions)
      .pipe(
        map((response: Response) => response)
      )
  }
  editAppointmentOption(data: any): Observable<any> {
    let requestData = JSON.stringify({
      'id': data.id,
      'store_name': JSON.parse(localStorage.getItem('currentUserProfile')).store_name,
      'name': data.name,
      'price': data.price,
      'email': JSON.parse(localStorage.getItem('currentUserProfile')).email
    });
    const url = environment.rootCloudUrl + 'editAppointmentOption';
    return this.http.post(url, requestData, httpBasicOptions)
      .pipe(
        map((response: Response) => response)
      )
  }

  deletePickupDay(id: any): Observable<any> {
    console.log(id);
    const url = environment.rootCloudUrl + 'deletePickupDay?store_id=' + JSON.parse(localStorage.getItem('currentUserProfile')).store_name + '&delivery_option_id=' + id;
    return this.http.delete(url, httpBasicOptions)
      .pipe(
        map((response: Response) => response)
      )
  }


  deleteProduct(id: any): Observable<any> {
    const url = environment.rootCloudUrl + 'deleteProduct?store_id=' + JSON.parse(localStorage.getItem('currentUserProfile')).store_name + '&product_id=' + id;
    return this.http.delete(url, httpBasicOptions)
      .pipe(
        map((response: Response) => response)
      )
  }


  deleteAdditionalProductImage(id: any, image_id: any): Observable<any> {
    const url = environment.rootCloudUrl + 'deleteAdditionalProductImage?store_id=' + JSON.parse(localStorage.getItem('currentUserProfile')).store_name + '&product_id=' + id + '&image_id=' + image_id;
    return this.http.delete(url, httpBasicOptions)
      .pipe(
        map((response: Response) => response)
      )
  }

  deleteReview(id: any): Observable<any> {
    const url = environment.rootCloudUrl + 'deleteReview?store_id=' + JSON.parse(localStorage.getItem('currentUserProfile')).store_name + '&product_id=' + id;
    return this.http.delete(url, httpBasicOptions)
      .pipe(
        map((response: Response) => response)
      )
  }


  deleteAllSlide(): Observable<any> {
    const url = environment.rootCloudUrl + 'deleteAllSlide?store_id=' + JSON.parse(localStorage.getItem('currentUserProfile')).store_name;
    return this.http.delete(url, httpBasicOptions)
      .pipe(
        map((response: Response) => response)
      )
  }

  deleteAllCategory(): Observable<any> {
    const url = environment.rootCloudUrl + 'deleteAllCategory?store_id=' + JSON.parse(localStorage.getItem('currentUserProfile')).store_name;
    return this.http.delete(url, httpBasicOptions)
      .pipe(
        map((response: Response) => response)
      )
  }



  deleteAllProduct(): Observable<any> {
    const url = environment.rootCloudUrl + 'deleteAllProduct?store_id=' + JSON.parse(localStorage.getItem('currentUserProfile')).store_name;
    return this.http.delete(url, httpBasicOptions)
      .pipe(
        map((response: Response) => response)
      )
  }




  uploadStoreLogo(event: any): Observable<any> {
    const file = event.target.files[0];
    let formData: FormData = new FormData();
    formData.append('id', JSON.parse(localStorage.getItem('currentUserProfile')).email);
    formData.append('store_name', JSON.parse(localStorage.getItem('currentUserProfile')).store_name);
    formData.append('photo', file);
    const url = environment.rootCloudUrl + 'uploadStoreLogo?store_name=' + JSON.parse(localStorage.getItem('currentUserProfile')).store_name;

    return this.http.post(url, formData)
      .pipe(
        map((response: Response) => response)
      )
  }

  uploadStoreBG(event: any): Observable<any> {
    const file = event.target.files[0];
    let formData: FormData = new FormData();
    formData.append('id', JSON.parse(localStorage.getItem('currentUserProfile')).email);
    formData.append('store_name', JSON.parse(localStorage.getItem('currentUserProfile')).store_name);
    formData.append('photo', file);
    const url = environment.rootCloudUrl + 'uploadStoreBG?store_name=' + JSON.parse(localStorage.getItem('currentUserProfile')).store_name;

    return this.http.post(url, formData)
      .pipe(
        map((response: Response) => response)
      )
  }



  changeStoreLinkStatus(data: any): Observable<any> {
    let requestData = JSON.stringify({
      "id": JSON.parse(localStorage.getItem('currentUserProfile')).email,
      "store_name": JSON.parse(localStorage.getItem('currentUserProfile')).store_name,
      "store_link_status": data.store_link_status,

    });
    const url = environment.rootCloudUrl + 'changeStoreLinkStatus';
    return this.http.post(url, requestData, httpBasicOptions)
      .pipe(
        map((response: Response) => response)
      )
  }



  checkAccountExists(data: any): Observable<any> {
    let requestData = JSON.stringify({
      "email": data.email
    });
    const url = environment.rootCloudUrl + 'checkAccountExists';
    return this.http.post(url, requestData, httpBasicOptions)
      .pipe(
        map((response: Response) => response)
      );
  }

  checkStoreExists(data: any): Observable<any> {
    let requestData = JSON.stringify({
      "store_name": data.email
    });
    const url = environment.rootCloudUrl + 'checkStoreExists';
    return this.http.post(url, requestData, httpBasicOptions)
      .pipe(
        map((response: Response) => response)
      );
  }

  updateStoreDetails(data: any): Observable<any> {
    let requestData = JSON.stringify({
      'account_email_id': JSON.parse(localStorage.getItem('currentUserProfile')).email,
      'email': data.email,
      "address": data.address,
      "phone_form": data.phone_form,
      "closing_time": data.closing_time,
      "facebook_link": data.facebook_link,
      "id": data.id,
      "instagram_link": data.instagram_link,
      "logo": data.logo,
      "minimum_delivery_order_price": 15,
      "minimum_order_price": 13.5,
      "name": data.name,
      "display_name": data.display_name,
      "delivery_charges": data.delivery_charges,
      "opening_time": data.opening_time,
      "owner_name": ' ',
      "store_link_status": data.store_link_status,
      "store_mode": ' ',
      "tagline": data.tagline,
      "order_mode": data.order_mode,
      "twitter_link": data.twitter_link,
      "website": data.website,
      "whatsapp_number": data.international_number,
      "youtube_link": data.youtube_link,
      "external_gallery_link": data.external_gallery_link,
      "country_code": data.country_code,
      "international_number": data.international_number,
      "currency_symbol": data.currency_symbol,
      "wipay_developer_id": (data.wipay_developer_id) ? (data.wipay_developer_id) : '',
      "wipay_api_key": (data.wipay_api_key) ? (data.wipay_api_key) : '',
      "enable_pay_button": (data.enable_pay_button) ? (data.enable_pay_button) : 'no',

      "is_custom_domain": (data.is_custom_domain) ? (data.is_custom_domain) : 'no',
      "is_shopping_enabled": (data.is_shopping_enabled) ? (data.is_shopping_enabled) : 'yes',
      "is_products_gallery_enabled": (data.is_products_gallery_enabled) ? (data.is_products_gallery_enabled) : 'no',


      "custom_domain_name": (data.custom_domain_name) ? (data.custom_domain_name) : '',
      "custom_portal_status": (data.custom_portal_status) ? (data.custom_portal_status) : 'active',
      "minimum_order_amount": (data.minimum_order_amount) ? (data.minimum_order_amount) : 0,


      "store_name_color": (data.store_name_color) ? (data.store_name_color) : "#000000",
      "store_description_color": (data.store_description_color) ? (data.store_description_color) : "#000000",
      "store_address_color": (data.store_address_color) ? (data.store_address_color) : "#000000",
    });
    const url = environment.rootCloudUrl + 'updateStoreDetails';

    //  console.table(JSON.parse(requestData));

    return this.http.post(url, requestData, httpBasicOptions)
      .pipe(
        map((response: Response) => response)
      )
  }


  createSlide(data: any): Observable<any> {
    // console.log(data);
    let requestData = JSON.stringify({
      'store_name': JSON.parse(localStorage.getItem('currentUserProfile')).store_name,
      'image': data.image,
    });
    const url = environment.rootCloudUrl + 'createSlide';

    // console.table(JSON.parse(requestData));

    return this.http.post(url, requestData, httpBasicOptions)
      .pipe(
        map((response: Response) => response)
      )
  }

  createCategory(data: any): Observable<any> {
    //  console.log(data);
    let requestData = JSON.stringify({
      'store_name': JSON.parse(localStorage.getItem('currentUserProfile')).store_name,
      'image': data.image,
      'name': data.name,
    });
    const url = environment.rootCloudUrl + 'createCategory';

    //le(JSON.parse(requestData));

    return this.http.post(url, requestData, httpBasicOptions)
      .pipe(
        map((response: Response) => response)
      )
  }

  editCategory(data: any): Observable<any> {
    //  console.log(data);
    let requestData = JSON.stringify({
      'id': data.id,
      'key': data.key,
      'store_name': JSON.parse(localStorage.getItem('currentUserProfile')).store_name,
      'image': data.image,
      'name': data.name,
    });
    const url = environment.rootCloudUrl + 'editCategory';

    //le(JSON.parse(requestData));

    return this.http.post(url, requestData, httpBasicOptions)
      .pipe(
        map((response: Response) => response)
      )
  }

  createCoupon(data: any): Observable<any> {
    //  console.log(data);
    let requestData = JSON.stringify({
      'email': JSON.parse(localStorage.getItem('currentUserProfile')).email,
      'store_name': JSON.parse(localStorage.getItem('currentUserProfile')).store_name,
      'code': data.code,
      'discount_percentage': data.discount_percentage,
    });
    const url = environment.rootCloudUrl + 'createCoupon';

    //le(JSON.parse(requestData));

    return this.http.post(url, requestData, httpBasicOptions)
      .pipe(
        map((response: Response) => response)
      )
  }

  createDeliveryOption(data: any): Observable<any> {
    //  console.log(data);
    let requestData = JSON.stringify({
      'email': JSON.parse(localStorage.getItem('currentUserProfile')).email,
      'store_name': JSON.parse(localStorage.getItem('currentUserProfile')).store_name,
      'name': data.name,
      'price': data.price,
    });
    const url = environment.rootCloudUrl + 'createDeliveryOption';

    //le(JSON.parse(requestData));

    return this.http.post(url, requestData, httpBasicOptions)
      .pipe(
        map((response: Response) => response)
      )
  }
  createBlog(reqDetails: any): Observable<any> {
    let requestData = JSON.stringify(
      {

        title: reqDetails.title,
        content: reqDetails.content,
        author: reqDetails.author,
        date_published: reqDetails.date_published, // assuming the field in reqDetails is camelCased
        slug: reqDetails.slug,
        excerpt: reqDetails.excerpt,
        categories: reqDetails.categories, // assuming this is an array
        tags: reqDetails.tags, // assuming this is an array
        featured_image: reqDetails.featured_image,
        thumbnail_image: reqDetails.thumbnail_image,
        gallery: reqDetails.gallery, // assuming this is an array of image URLs
        image_caption: reqDetails.image_caption,
        status: reqDetails.status,
        seo_title: reqDetails.seo_title,
        meta_description: reqDetails.meta_description,
        reading_time: reqDetails.reading_time,
        comments: [], // starting with an empty array
        likes: 0, // starting with 0 likes
        email: JSON.parse(localStorage.getItem('currentUserProfile')).email,
        store_name: JSON.parse(localStorage.getItem('currentUserProfile')).store_name,
        share_links: {
          facebook: `https://facebook.com/sharer/sharer.php?u=${reqDetails.perma_link}`,
          twitter: `https://twitter.com/share?url=${reqDetails.perma_link}&text=${reqDetails.title}`,
          linkedin: `https://linkedin.com/shareArticle?mini=true&url=${reqDetails.perma_link}&title=${reqDetails.title}`
        },
        perma_link: reqDetails.perma_link,
        custom_fields: reqDetails.custom_fields // assuming this is an object
      }
    );
    const url = environment.rootCloudUrl + 'createBlog';

    //le(JSON.parse(requestData));

    return this.http.post(url, requestData, httpBasicOptions)
      .pipe(
        map((response: Response) => response)
      )
  }
  editDeliveryOption(data: any): Observable<any> {
    //  console.log(data);
    let requestData = JSON.stringify({
      'id': data.id,
      'email': JSON.parse(localStorage.getItem('currentUserProfile')).email,
      'store_name': JSON.parse(localStorage.getItem('currentUserProfile')).store_name,
      'name': data.name,
      'price': data.price,
    });
    const url = environment.rootCloudUrl + 'editDeliveryOption';

    //le(JSON.parse(requestData));

    return this.http.post(url, requestData, httpBasicOptions)
      .pipe(
        map((response: Response) => response)
      )
  }



  editBankOption(data: any): Observable<any> {
    //  console.log(data);
    let requestData = JSON.stringify({
      'id': data.id,
      'email': JSON.parse(localStorage.getItem('currentUserProfile')).email,
      'store_name': JSON.parse(localStorage.getItem('currentUserProfile')).store_name,
      'name': data.name,
      'bank_details': data.bank_details
    });
    const url = environment.rootCloudUrl + 'editBankOption';

    //le(JSON.parse(requestData));

    return this.http.post(url, requestData, httpBasicOptions)
      .pipe(
        map((response: Response) => response)
      )
  }

  createBankOption(data: any): Observable<any> {
    //  console.log(data);
    let requestData = JSON.stringify({
      'email': JSON.parse(localStorage.getItem('currentUserProfile')).email,
      'store_name': JSON.parse(localStorage.getItem('currentUserProfile')).store_name,
      'name': data.name,
      'bank_details': data.bank_details
    });
    const url = environment.rootCloudUrl + 'createBankOption';

    //le(JSON.parse(requestData));

    return this.http.post(url, requestData, httpBasicOptions)
      .pipe(
        map((response: Response) => response)
      )
  }


  createAppointmentOption(data: any): Observable<any> {
    //  console.log(data);
    let requestData = JSON.stringify({
      'email': JSON.parse(localStorage.getItem('currentUserProfile')).email,
      'store_name': JSON.parse(localStorage.getItem('currentUserProfile')).store_name,
      'name': data.name,
      'price': data.price
    });
    const url = environment.rootCloudUrl + 'createAppointmentOption';

    //le(JSON.parse(requestData));

    return this.http.post(url, requestData, httpBasicOptions)
      .pipe(
        map((response: Response) => response)
      )
  }


  createPickupDay(data: any): Observable<any> {
    //  console.log(data);
    let requestData = JSON.stringify({
      'email': JSON.parse(localStorage.getItem('currentUserProfile')).email,
      'store_name': JSON.parse(localStorage.getItem('currentUserProfile')).store_name,
      'name': data.name
    });
    const url = environment.rootCloudUrl + 'createPickupDay';

    //le(JSON.parse(requestData));

    return this.http.post(url, requestData, httpBasicOptions)
      .pipe(
        map((response: Response) => response)
      )
  }


  createProduct(data: any): Observable<any> {
    let requestData = JSON.stringify({
      'store_name': JSON.parse(localStorage.getItem('currentUserProfile')).store_name,
      'image': data.image,
      'category_id': data.category_id,
      'name': data.name,
      'price': data.price,
      'discount_price': data.discount_price,
      'description': data.description,
      'delivery_description': data.delivery_description,
      'best_choice': data.best_choice,
      'grade_a': data.grade_a,
      'out_of_stock': data.out_of_stock,
      'stock_availability': data.stock_availability,

      'minimum_buying_quantity': (data.minimum_buying_quantity) ? (data.minimum_buying_quantity) : null,
      'wholesale_lot_size': (data.wholesale_lot_size) ? (data.wholesale_lot_size) : null,
      'available_stock_quantity': (data.available_stock_quantity) ? (data.available_stock_quantity) : null,

      'additional_images': data.additional_images,
    });
    const url = environment.rootCloudUrl + 'createProduct';

    // console.table(JSON.parse(requestData));

    return this.http.post(url, requestData, httpBasicOptions)
      .pipe(
        map((response: Response) => response)
      )
  }

  createReview(data: any): Observable<any> {
    let requestData = JSON.stringify({
      'store_name': JSON.parse(localStorage.getItem('currentUserProfile')).store_name,
      'image': data.image,
      'category_id': 'false',
      'name': data.name,
      'price': data.price,
      'discount_price': data.discount_price,
      'description': data.description,
      'stock_availability': data.stock_availability,
    });
    const url = environment.rootCloudUrl + 'createReview';

    // console.table(JSON.parse(requestData));

    return this.http.post(url, requestData, httpBasicOptions)
      .pipe(
        map((response: Response) => response)
      )
  }


  editProduct(data: any): Observable<any> {
    let requestData = JSON.stringify({
      'id': data.id,
      'key': data.key,
      'store_name': JSON.parse(localStorage.getItem('currentUserProfile')).store_name,
      'image': data.image,
      'category_id': data.category_id,
      'name': data.name,
      'price': data.price,
      'discount_price': data.discount_price,
      'description': data.description,
      'delivery_description': data.delivery_description,
      'best_choice': data.best_choice,
      'grade_a': data.grade_a,
      'out_of_stock': data.out_of_stock,
      'stock_availability': data.stock_availability,

      'minimum_buying_quantity': (data.minimum_buying_quantity) ? (data.minimum_buying_quantity) : null,
      'wholesale_lot_size': (data.wholesale_lot_size) ? (data.wholesale_lot_size) : null,
      'available_stock_quantity': (data.available_stock_quantity) ? (data.available_stock_quantity) : null,


    });
    const url = environment.rootCloudUrl + 'editProduct';

    // console.table(JSON.parse(requestData));

    return this.http.post(url, requestData, httpBasicOptions)
      .pipe(
        map((response: Response) => response)
      )
  }


  manageProductOption(data: any): Observable<any> {
    const url = environment.rootCloudUrl + 'manageProductOption';
    return this.http.post(url, data, httpBasicOptions)
      .pipe(
        map((response: Response) => response)
      )
  }

  manageProductExtra(data: any): Observable<any> {
    const url = environment.rootCloudUrl + 'manageProductExtra';
    return this.http.post(url, data, httpBasicOptions)
      .pipe(
        map((response: Response) => response)
      )
  }

  manageProductExternalRedirectionButton(data: any): Observable<any> {
    const url = environment.rootCloudUrl + 'manageProductExternalRedirectionButton';
    return this.http.post(url, data, httpBasicOptions)
      .pipe(
        map((response: Response) => response)
      )
  }



  createProductOption(data: any): Observable<any> {
    const url = environment.rootCloudUrl + 'createProductOption';
    return this.http.post(url, data, httpBasicOptions)
      .pipe(
        map((response: Response) => response)
      )
  }
  createProductExtra(data: any): Observable<any> {
    const url = environment.rootCloudUrl + 'createProductExtra';
    return this.http.post(url, data, httpBasicOptions)
      .pipe(
        map((response: Response) => response)
      )
  }


  deleteOrder(id: any): Observable<any> {
    const url = environment.rootCloudUrl + 'deleteOrder?store_id=' + JSON.parse(localStorage.getItem('currentUserProfile')).store_name + '&order_id=' + id;
    return this.http.delete(url, httpBasicOptions)
      .pipe(
        map((response: Response) => response)
      )
  }


  deleteAccount(id: any, name: any, secret: any): Observable<any> {

    let requestData = JSON.stringify({
      'store_id': id,
      'store_name': name,
      'secret': secret
    });

    const url = environment.rootCloudUrl + 'deleteAccount';
    return this.http.post(url, requestData, httpAdminBasicOptions)
      .pipe(
        map((response: Response) => response)
      )
  }



  deleteSeletedOrders(ids: any): Observable<any> {
    let requestData = JSON.stringify({
      "id": JSON.parse(localStorage.getItem('currentUserProfile')).email,
      "store_id": JSON.parse(localStorage.getItem('currentUserProfile')).store_name,
      "order_ids": ids,

    });
    const url = environment.rootCloudUrl + 'deleteSeletedOrders';
    return this.http.post(url, requestData, httpBasicOptions)
      .pipe(
        map((response: Response) => response)
      )
  }

  updateCategoryStatus(id: any, key: any, status: any): Observable<any> {
    const url = environment.rootCloudUrl + 'updateCategoryStatus?store_id=' + JSON.parse(localStorage.getItem('currentUserProfile')).store_name + '&id=' + id + '&status=' + status + '&key=' + key;
    return this.http.delete(url, httpBasicOptions)
      .pipe(
        map((response: Response) => response)
      )
  }

  updateProductStatus(id: any, key: any, status: any): Observable<any> {
    const url = environment.rootCloudUrl + 'updateProductStatus?store_id=' + JSON.parse(localStorage.getItem('currentUserProfile')).store_name + '&id=' + id + '&status=' + status + '&key=' + key;
    return this.http.delete(url, httpBasicOptions)
      .pipe(
        map((response: Response) => response)
      )
  }


  updateOrderStatus(id: any, status: any): Observable<any> {
    const url = environment.rootCloudUrl + 'updateOrderStatus?store_id=' + JSON.parse(localStorage.getItem('currentUserProfile')).store_name + '&order_id=' + id + '&order_status=' + status;
    return this.http.delete(url, httpBasicOptions)
      .pipe(
        map((response: Response) => response)
      )
  }

  updateAccountPlan(id: any, store: any, status: any): Observable<any> {
    const url = environment.rootCloudUrl + 'updateAccountPlan?store_id=' + store + '&account_id=' + id + '&store_plan=' + status;
    return this.http.delete(url, httpBasicOptions)
      .pipe(
        map((response: Response) => response)
      )
  }


  updateOrderStatusBulk(ids: any, status: any): Observable<any> {
    let requestData = JSON.stringify({
      "id": JSON.parse(localStorage.getItem('currentUserProfile')).email,
      "store_id": JSON.parse(localStorage.getItem('currentUserProfile')).store_name,
      "order_ids": ids,
      "order_status": status,

    });
    const url = environment.rootCloudUrl + 'updateOrderStatusBulk';
    return this.http.post(url, requestData, httpBasicOptions)
      .pipe(
        map((response: Response) => response)
      )
  }

  getStoreDashboard(data: any): Observable<any> {
    let requestData = JSON.stringify({
      "store_id": JSON.parse(localStorage.getItem('currentUserProfile')).store_name
    });
    const url = environment.rootCloudUrl + 'getStoreDashboard';
    return this.http.post(url, requestData, httpBasicOptions)
      .pipe(
        map((response: Response) => response)
      )
  }


  getAllStoreAccountsDetails(data: any): Observable<any> {
    let requestData = JSON.stringify({
      "email": data.email
    });
    const url = environment.rootCloudUrl + 'getAllStoreAccountsDetails';
    return this.http.post(url, requestData, httpAdminBasicOptions)
      .pipe(
        map((response: Response) => response)
      )
  }




  createProductSpecification(data: any): Observable<any> {
    const url = environment.rootCloudUrl + 'createProductSpecification';
    return this.http.post(url, data, httpBasicOptions)
      .pipe(
        map((response: Response) => response)
      )
  }

  deleteProductSpecifications(id: any, productId: any): Observable<any> {
    console.log(id);
    const url = environment.rootCloudUrl + 'deleteProductSpecifications?store_id=' + JSON.parse(localStorage.getItem('currentUserProfile')).store_name + '&product_specification_id=' + id + '&product_id=' + productId;
    return this.http.delete(url, httpBasicOptions)
      .pipe(
        map((response: Response) => response)
      )
  }

  manageProductSpecification(data: any): Observable<any> {
    const url = environment.rootCloudUrl + 'manageProductSpecification';
    return this.http.post(url, data, httpBasicOptions)
      .pipe(
        map((response: Response) => response)
      )
  }


}




