import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'KiFyKit';
  storeLink: any;
  constructor(private router: Router) {

    if (JSON.parse(localStorage.getItem('currentUserProfile'))) {
      this.storeLink = environment.rootStoreUrl + JSON.parse(localStorage.getItem('currentUserProfile')).store_name;

    }

  }
  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });
  }
}
