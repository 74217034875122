import { Component, OnInit } from '@angular/core';

import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss']
})
export class AdminLayoutComponent implements OnInit {
  storeLink: any;
  constructor() { 
    if (JSON.parse(localStorage.getItem('currentUserProfile'))) {
      this.storeLink = environment.rootStoreUrl + JSON.parse(localStorage.getItem('currentUserProfile')).store_name;
    }
  }

  ngOnInit() {
  }

}
