import { HttpHeaders } from '@angular/common/http';

export const environment = {
  production: false,
  rootCloudUrl: 'https://us-central1-cartonfly-7ee9b.cloudfunctions.net/',
  rootStoreUrl: 'http://store.kifykit.com/',
  inventoryTemplaterl: 'https://storage.googleapis.com/cartonfly-7ee9b.appspot.com/template/www.CartOnFly.com%20-%20Store%20Setup%20Template-v1.xlsx',
  storeId: 'cae68baf-ff14-4204-a513-805660e0138c',
  globalPushNotificationsTopic: 'cof_app'
};

export const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
export const httpBasicOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Basic ' + btoa('cof' + ':' + 'cof@123!') })
};
export const httpAdminBasicOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Basic ' + btoa('$admin.cof$' + ':' + '$admin.cof@123!$') })
};
