import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser'
import { CommonModule } from "@angular/common";

import { AppComponent } from './app.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';




import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AuthGuard } from './services/auth.guard'
import { NgxSpinnerModule } from "ngx-spinner";
import { ToastrModule } from 'ngx-toastr';

// import {Ng2TelInputModule} from 'ng2-tel-input';

import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { NgbPaginationModule, NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
//import { CurrencySymbolPipe } from './currency-symbol.pipe';


var config = {
  apiKey: "AIzaSyBFKHByMAQHcmu2daZ3UlQfzE046-zguLY",
  authDomain: "cartonfly-7ee9b.firebaseapp.com",
  databaseURL: "https://cartonfly-7ee9b-default-rtdb.firebaseio.com",
  projectId: "cartonfly-7ee9b",
  storageBucket: "cartonfly-7ee9b.appspot.com",
  messagingSenderId: "265385952932",
  appId: "1:265385952932:web:50a75a0ec37272b503c831",
  measurementId: "G-MDZD7W406H"
};

@NgModule({
  imports: [
    // Ng2TelInputModule,
    BsDropdownModule.forRoot(),
    NgxIntlTelInputModule,
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    ToastrModule.forRoot(),
    HttpClientModule,
    ComponentsModule,
    NgbModule,
    RouterModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(config),
    AngularFirestoreModule,
    AngularFireAuthModule,
    NgbPaginationModule, NgbAlertModule
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    //CurrencySymbolPipe
  ],
  //exports: [CurrencySymbolPipe],
  providers: [AuthGuard],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
